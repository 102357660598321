<!--
* @FileDescription: 门店管理
* @Author: chenz
* @Date: 2021/4/28
 * @LastEditors: chenz
 * @LastEditTime: 2021-11-18 10:54:21
-->
<template>
  <div class="store-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      :isEdit="isEdit"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      ref="form"
      :form="form"
      :rules="rules"
      :visible="visible"
      :title="dialogTitle"
      :formItemList="formItemList"
      @_Close="_Close"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @_handleSelectChange="_handleSelectChange(arguments)"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入侧边栏json
// const formItemList = require("./formItemList")

// 引入接口
import { listByPage, addUserMember,delUserMember } from "@/api/member-manage"

import { updateUser } from "../../api/user"

// 引入方法
import {
  getExcelLabelOrProp,
  getPhoneValidator,
  getIDCardValidator,
} from "../../utils/tool"

import http from "../../api/http"

import {
  getStoreOptions,
  getUserOptions,
  getProjectOptionsBySid,
} from "../../api/select-options"

export default {
  name: "StoreManage",

  data() {
    var moneyValidate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"))
      } else if (value !== "1") {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }

    return {
      // 查询表单
      queryForm: {
        name: "",
      },

      isEdit: false,

      // 查询表单项
      queryFormItemList: [
        { type: "input", prop: "name", placeholder: "请输入要查询的名称" },
      ],

      // 列接受的参数
      columns: [
        { label: "用户名", prop: "userName" },
        {
          label: "会员卡类型",
          prop: "type",
        },
        { label: "所属门店", prop: "storeName" },
        { label: "储值卡余额", prop: "money" },
        { label: "赠送金额余额", prop: "gift" },
        { label: "储值卡项目", prop: "pname" },
        { label: "项目剩余次数", prop: "times" },
        { label: "创建时间", prop: "createTime" },
        // {
        //   label: "备注",
        //   prop: "remark",
        //   ,
        //   showOverFlowTooltip: true,
        // },
      ],

      // 具体数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "添加会员",

      // 弹框显示
      visible: false,

      // 表单
      form: {
        type: "",
        storeId: "",
        userId: "",
        money: "",
        pid: "",
        times: "",
        remark: "",
      },

      // 校验
      rules: {
        type: [
          { required: true, message: "会员卡类型不能为空", trigger: "change" },
        ],
        storeId: [
          { required: true, message: "店铺不能为空", trigger: "change" },
        ],
        userId: [
          { required: true, message: "用户不能为空", trigger: "change" },
        ],
        money: [
          {
            required: true,
            message: "充值金额不能为空",
            trigger: "change",

            // validator: moneyValidate,
          },
        ],
        pid: [{ required: true, message: "项目不能为空", trigger: "change" }],
        times: [
          { required: true, message: "项目次数不能为空", trigger: "change" },
        ],
      },

      // 表单数据
      formItemList: [
        {
          label: "会员卡类型",
          type: "select",
          prop: "type",
          value: "type",
          placeholder: "请选择会员卡类型",
          options: [
            {
              id: 0,
              name: "现金储值卡",
            },
            {
              id: 1,
              name: "项目储值卡",
            },
          ],
          cashFlag: true,
          // handleSelectChange: this._handleSelectChange,
        },
        {
          label: "所属店铺",
          type: "select",
          prop: "storeId",
          value: "storeId",
          placeholder: "请选择店铺",
          options: [],
          cashFlag: true,
        },
        {
          label: "用户",
          type: "select",
          prop: "userId",
          value: "userId",
          placeholder: "请选择用户",
          options: [],
          filterable: true,
        },
        {
          label: "充值金额",
          type: "input-number",
          prop: "money",
          value: "money",
          placeholder: "请输入充值金额",
        },
        {
          label: "充值项目",
          type: "select",
          prop: "pid",
          value: "pid",
          placeholder: "请选择项目",
          options: [],
        },

        {
          label: "充值次数",
          type: "input-number",
          prop: "times",
          value: "times",
          placeholder: "请输入充值次数",
        },
        {
          label: "备注",
          type: "textarea",
          prop: "remark",
          value: "remark",
          placeholder: "请输入备注",
        },
      ],

      baseURL: "",

      allUserList: [],
    }
  },

  mounted() {
    this.baseURL = ""
    // this.baseURL = http.defaults.baseURL
    this.getData()

    //获取店铺选项
    this.getStoreOptions()

    //获取用户选项
    this.getUserOptions()

    // 根据门店获取项目
    this.getProjectOptionsBySid()

    // const index = this.formItemList.findIndex((_) => _.prop === "type")
    // this.formItemList[index].handleSelectChange = this._handleSelectChange
    // console.log(1111, this.formItemList)
  },

  methods: {
    // 获取所属店铺
    async getStoreOptions() {
      try {
        const res = await getStoreOptions()
        const index = this.formItemList.findIndex((_) => _.prop === "storeId")
        this.formItemList[index].options = res.data.records
      } catch (error) {
        console.log(error)
      }
    },

    // 获取用户选项
    async getUserOptions() {
      try {
        const res = await getUserOptions()
        const index = this.formItemList.findIndex((_) => _.prop === "userId")
        this.allUserList = res.data.map((e) => {
          e["name"] = e.realName || e.nickName
          return e
        })
        this.formItemList[index].options = this.allUserList

        // this._handleFilter()
      } catch (error) {
        console.log(error)
      }
    },

    // 根据门店获取项目
    async getProjectOptionsBySid(param = "") {
      try {
        const res = await getProjectOptionsBySid(param)
        const index = this.formItemList.findIndex((_) => _.prop === "pid")
        this.formItemList[index].options = res.data
      } catch (error) {
        console.log(error)
      }
    },
    // 查询
    _query(form) {
      this.getData()
    },

    // 添加
    _add() {
      console.log("添加", this.form)
      this.visible = true
      this.dialogTitle = "添加店铺会员"
      this.form = {}

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
    },

    // 导出
    _export(form) {
      // console.log("导出", form)
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        console.log(
          "_export",
          this.selectChangeTableData.length,
          this.selectChangeTableData
        )
        console.log("this.tableData", this.tableData)
        console.log("list", list)
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "店铺会员管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 多选事件
    _handleSelectionChange(val) {
      this.selectChangeTableData = val
      console.log("选择要导出的", this.selectChangeTableData)
    },

    // 修改
    _edit(row) {
      console.log("修改", row)
      this.dialogTitle = "修改店铺会员"
      this.visible = true
      this.form = Object.assign({}, row)
      // const index = this.formItemList.filter(f=> f.prop === 'pid' || f.prop === 'money' || f.  )findIndex((_) => _.prop === "pid")
      // this.formItemList[index].disabled = true

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
    },

    // 删除
    async _del(row) {
      this.$confirm("你确定要删除此会员吗？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delUserMember(row.id)
          if (res.code !== 200) {
            return this.$message({ message: "删除失败", type: "error" })
          }
          this.$forceUpdate()
          this.$message({
            type: "success",
            message: "删除成功!",
          })
          this.getData()
          this.$forceUpdate()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    _cancel() {
      this.closeForm()
    },

    // 确定
    _confirm() {
      console.log("确定", this.form)
      this.$refs.form.$refs.form.validate(async (valid) => {
        if (valid) {
          !this.form.id ? this.add() : this.edit()
        } else {
          return false
        }
      })
    },

    // 添加接口
    async add() {
      try {
        const res = await addUserMember(this.form)
        console.log("添加接口", res)
        if (res.code !== 200) {
          return this.$message({
            showClose: true,
            message: "添加店铺会员失败",
            type: "error",
          })
        }
        this.$message({
          showClose: true,
          message: "添加店铺会员成功",
          type: "success",
        })
        this.visible = false
        this.getData()
      } catch (error) {
        this.visible = false
        this.$message({
          showClose: true,
          message: "添加店铺会员失败",
          type: "error",
        })
      }
    },

    // 修改接口
    async edit() {
      try {
        const res = await updateStore(this.form.id, this.form)
        // console.log("修改接口", res)
        if (res.code !== 200) {
          return this.$message({
            showClose: true,
            message: "修改店铺会员失败",
            type: "error",
          })
        }
        this.$message({
          showClose: true,
          message: "修改店铺会员成功",
          type: "success",
        })
        this.visible = false
        this.getData()
      } catch (error) {}
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
      this.visible = false // 关闭弹框
    },

    // 获取用户会员卡数据
    async getData() {
      this.loading = true
      const { limit, page } = this.pagingOptions
      try {
        const res = await listByPage(limit, page, this.queryForm)
        this.loading = false
        this.tableData = res.data.list || res.data.records
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    _handleSelectChange(args) {
      console.log("_handleSelectChange", args)
      var value = args[0]
      var item = args[1]

      if (item.prop === "type") {
        const moneyItem = this.formItemList.find((_) => _.prop === "money")
        const pidItem = this.formItemList.find((_) => _.prop === "pid")
        const timesItem = this.formItemList.find((_) => _.prop === "times")

        // item.disabled = false

        moneyItem.disabled = !(value === 0)
        pidItem.disabled = value === 0
        timesItem.disabled = value === 0

        this.rules["money"][0].required = value === 0
        this.rules["pid"][0].required = value === 1
        this.rules["times"][0].required = value === 1

        // this.$refs.form.$refs.form.clearValidate("money")
        console.log("this.rules", this.rules)
        // this.$nextTick(() => {
        //   this.$refs.form.$refs.form.clearValidate("money")
        // })
      }

      if (item.prop === "storeId") {
        this.getProjectOptionsBySid(value)
      }
    },

    _handleFilter(e = "") {
      console.log("_handleFilter", e)
      // console.log("options", this.allUserList)
      let arr = this.allUserList.filter((item) => {
        return item.name && item.name.indexOf(e.trim()) > -1
      })

      const index = this.formItemList.findIndex((_) => _.prop === "userId")
      this.formItemList[index].options = arr

      // if (arr.length > 50) {
      //   this.formItemList[index].options = arr.slice(0, 50)
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.store-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>