import request from "./http"

/**
 * @description 获取店铺数据
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 *  @param  {Object}}data 参数
 * @return 返回店铺数据
 */
export function listByPage(limit, page, data) {
  return request({
    url: `/api/admin/userMember/list?limit=${limit}&page=${page}`,
    method: "post",
    data,
  })
}

/**
 * @description 删除会员
 *  @param {Object} data 表单数据
 * @return 新增成功
 */
export function addUserMember(data) {
  return request({
    url: "/api/admin/userMember/create",
    method: "post",
    data,
  })
}

/**
 * @description 删除会员
 *  @param {String} id 会员id
 * @return
 */
export function delUserMember(id) {
  return request({
    url: `/api/admin/userMember/delete/${id}`,
    method: "post",
  })
}

/**
 * @description 修改店铺
 *  @param {String} id 店铺id
 *  @param {Array} data 表单数据
 * @return
 */
export function updateStore(id, data) {
  return request({
    url: `/api/admin/store/update/${id}`,
    method: "post",
    data,
  })
}
